<template>
  <div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
    <div v-show="!isSpinner">

      <!-- Filters -->
      <!-- <users-list-filters
        :role-filter.sync="roleFilter"
        :plan-filter.sync="planFilter"
        :status-filter.sync="statusFilter"
        :role-options="roleOptions"
        :plan-options="planOptions"
        :status-options="statusOptions"
      /> -->
      
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Search -->
            <b-col
              cols
              md="6"            
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <div>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <!-- <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Add User</span>
                </b-button> -->
              </div>
            </b-col>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <!-- <label>entries</label> -->
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          responsive
          hover
          @row-clicked="handleRowClick"
          :fields="tableColumns"
          primary-key="id"
          :show-empty="!isSpinner"
          empty-text="No matching records found"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: No. -->
          <template #cell(sr_no)="data">
            <div>
              <span>{{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}</span>
            </div>
          </template>

          <!-- Column: Role -->
          <template #cell(employee_role)="data">
            <div style="white-space: nowrap">
              {{data.item.employee_role?data.item.employee_role.name:'--'}}
            </div>
          </template>

          <!-- Column: Action -->
          <template #cell(action)="data">
            <div style="white-space: nowrap" v-if="$route.meta.action=='write' || $route.meta.action==undefined">
              <a><img src='@/assets/images/erflog/Edit.png' class="action_icon" @click.prevent="$router.push({name:'edit-employee',params:{name:data.item.employee_name,id:data.item.id}})"/></a>
              <a><img src='@/assets/images/erflog/Delete.png' class="action_icon" @click.prevent="selectedUser=data,$bvModal.show('modal-delete-employee')"/></a>
            </div>
            <div v-else>
              --
            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    
    <!-- Delete Employee -->
    <b-modal
      id="modal-delete-employee"
      cancel-variant="outline-secondary"
      ok-title="Delete"
      ok-variant="danger"
      cancel-title="Cancel"
      centered
      title=""
      :no-close-on-backdrop="true"
      @hidden="selectedUser='',representative=null"
      @ok="deleteEmployeeFunction"
    >
    <div class="create_account_img">
      <img src="@/assets/images/erflog/DeletePopup.png" alt="">
    </div>
      <div style="text-align: center;" v-if="selectedUser!=''">
        <div v-if="selectedUser.item.noOfStudentAssignee != 0">
            <validation-observer ref="assignedstudent">
            <validation-provider
                #default="{ errors }"
                name="Representative"
                rules="required"
            >
              <b-col md="12">
                <b-form-group label="Representative">
                  Before deleting this employee, please select the student representative of the assigned student of this employee.
                    <b-form-select
                        v-model="representative"
                        :options="representative1"
                        placeholder="Select"
                    />
                </b-form-group>
              </b-col>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </validation-observer>
        </div>
        <label class="s_modal_title">Delete Employee</label>
        <p>Are you sure you want to delete this employee ?</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BSpinner,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormSelect,BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useEmployeeList'

import firebase from '@/utils/firebaseInit'
import { dbCollections } from '@/utils/firebaseCollection'
const db = firebase.firestore()
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
extend('required',required)

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormSelect,
    BFormGroup,
    vSelect,
    BSpinner,
  },
  created(){
    let self = this;
    db
      .collection(dbCollections.USERS)
      .orderBy('createdAt')
      .get()
      .then((querySnapshot)=>{
        // let employee = localStorage.getItem('currentUser');
          // console.log(JSON.parse(employee),'employee')
          let representativeArray = [];
          let employeeArry = [];
          querySnapshot.forEach(doc=>{
            if(!doc.data().isDeleted && (doc.data().role == 'employee' || doc.data().role == 'admin')) {
                self.userDatas.push(doc.data());
                if(doc.data().id == firebase.auth().currentUser.uid){
                    self.currenUserObject = doc.data();
                }
                if(doc.data().representative){
                    employeeArry.push({id:doc.data().id,rId:doc.data().representative.id})
                } else {
                    employeeArry.push({id:doc.data().id})
                }
            }
          })
          function findRelatedRIds(id) {
              const relatedRIds = [];
              relatedRIds.push(id)
              const visitedIds = new Set();

              function traverseRelations(currentId) {
                  visitedIds.add(currentId);
                  
                  for (const relation of employeeArry) {
                      if (relation.rId === currentId && !visitedIds.has(relation.id)) {
                          relatedRIds.push(relation.id);
                          traverseRelations(relation.id);
                      }
                  }
              }
              
              traverseRelations(id);
              
              return relatedRIds;
          }
          let id = firebase.auth().currentUser.uid
          representativeArray = findRelatedRIds(id)
          // employeeArry.forEach((item)=>{
          //     // console.log(item.id,'=====',self.docId,'=======',item.rId,'======')
          //     if(representativeArray.length === 0) {
          //         if(item.id == firebase.auth().currentUser.uid){
          //             representativeArray.push(firebase.auth().currentUser.uid)
          //         }
          //         if(item.rId){
          //             if(item.rId == firebase.auth().currentUser.uid){
          //                 representativeArray.push(item.id)
          //             }
          //         }
          //     } else {
          //         if(item.rId){
          //             if(representativeArray.includes(item.rId)){
          //                 representativeArray.push(item.id)
          //             }
          //         }
          //     }
          // })
          querySnapshot.forEach(doc=>{
              if(!doc.data().isDeleted && doc.data().role == 'employee' && (self.currenUserObject.role == 'employee' && representativeArray.includes(doc.data().id) || self.currenUserObject.role == 'admin')){
                self.representativeOptions1.push({value:{name:doc.data().name,eid:doc.data().employeeId,id:doc.data().id,phoneNo:doc.data().personalPhoneNo},text:doc.data().name})
                // if(JSON.parse(employee).role == 'employee' && JSON.parse(employee).id !== doc.data().id)
                // {
                //   self.representativeOptions1.push({value:{name:doc.data().name,eid:doc.data().employeeId,id:doc.data().id,phoneNo:doc.data().personalPhoneNo},text:doc.data().name})
                // } else if(JSON.parse(employee).role !== 'employee') {
                //   self.representativeOptions1.push({value:{name:doc.data().name,eid:doc.data().employeeId,id:doc.data().id,phoneNo:doc.data().personalPhoneNo},text:doc.data().name})
                // }
              }
          })
      })
      .catch(error=>console.log(error))
  },
  data(){
    return{
      selectedUser:"",
      university:null,
      representative:null,
      status:null,
      universityOptions:[
        { value: null, text: 'All' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Simple Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true },
      ],
      representativeOptions:[
        { value: null, text: 'All' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Simple Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true },
      ],
      statusOptions:[
        { value: null, text: 'All' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Simple Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true },
      ],
      representativeOptions1:[{value:null,text:'Select'}],
      currenUserObject:null,
      docId:'',
      userDatas:[],
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      isSpinner,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      deleteEmployee,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      isSpinner,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      deleteEmployee,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  methods:{
    deleteEmployeeFunction(bvModalEvent)
    {
      var self = this
      let representativeEmployee = {
        eid:self.currenUserObject.employeeId ? self.currenUserObject.employeeId : '',
        id:self.currenUserObject.id,
        phoneNo:self.currenUserObject.personalPhoneNo ? self.currenUserObject.personalPhoneNo : '',
        name:self.currenUserObject.name,
      }
      if(self.selectedUser.item.noOfStudentAssignee !== 0 || self.representative !== null) {
        bvModalEvent.preventDefault()
        self.$refs.assignedstudent.validate().then(success => {
          if(success == false) {
            return
          } else {
            let repre = self.representative;
            self.representative = null;
            var data = self.selectedUser
            self.$bvModal.hide('modal-delete-employee');
              let index = this.representativeOptions1.findIndex((item)=>{
                return item.value != null && item.value.id == data.item.id
              })
              if(index!=-1){
                this.representativeOptions1.splice(index,1)
              }
              self.$axios.post(dbCollections.APIURL+'removeUserFromAuth',{uid:data.item.id})
                .then((resp)=>{
                  if(resp.data.status==200)
                  {
                    self.deleteEmployee(data,repre,representativeEmployee,(status,msg)=>{
                      if(status)
                      {
                        self.$root.$emit('showToastMessage','Employee deleted successfully.','success')
                      }
                      else
                      {
                        self.$root.$emit('showToastMessage',msg,'danger')
                      }
                    })
                  }
                  else
                  {
                    self.deleteEmployee(data,repre,representativeEmployee,(status,msg)=>{
                      if(status)
                      {
                        self.$root.$emit('showToastMessage','Employee deleted successfully.','success')
                      }
                      else
                      {
                        self.$root.$emit('showToastMessage',msg,'danger')
                      }
                    })
                  }
                })
                .catch(error=>{
                  self.$bvModal.hide('modal-delete-employee');
                  self.$root.$emit('showToastMessage',error.message,'danger')
                  console.log(error)
                })
          }
  
        }).catch(error => {
          self.$bvModal.hide('modal-delete-employee');
          self.$root.$emit('showToastMessage',error,'danger')
        });
      } else {
          var data = self.selectedUser
          self.$bvModal.hide('modal-delete-employee');
          self.$axios.post(dbCollections.APIURL+'removeUserFromAuth',{uid:data.item.id})
          .then((resp)=>{
            if(resp.data.status==200)
            {
              self.deleteEmployee(data,'',representativeEmployee,(status,msg)=>{
                if(status)
                {
                  self.$root.$emit('showToastMessage','Employee deleted successfully.','success')
                }else
                {
                  self.$root.$emit('showToastMessage',msg,'danger')
                }
              })
            }
            else
            {
              self.$bvModal.hide('modal-delete-employee');
              self.deleteEmployee(data,'',representativeEmployee,(status,msg)=>{
                if(status)
                {
                  self.$root.$emit('showToastMessage','Employee deleted successfully.','success')
                }
                else
                {
                  self.$root.$emit('showToastMessage',msg,'danger')
                }
              })
            }
          })
          .catch(error=>{
            self.$bvModal.hide('modal-delete-employee');
            self.$root.$emit('showToastMessage',error.message,'danger')
            console.log(error)
          })
      }
    },
    handleRowClick(data)
    {
      // console.log(data)
      //Show User Details
      this.$router.push({name:'employee-information',params:{name:data.employee_name,id:data.id}})
    },
    createAccount(e)
    {
      var index = (this.currentPage-1) * this.perPage + this.selectedUser.index + 1
      // console.log("createAccount",this.selectedUser,index)
      this.selectedUser=''
    },
    closeInquiry(e)
    {
      var index = (this.currentPage-1) * this.perPage + this.selectedUser.index + 1
      // console.log("closeInquiry",this.selectedUser,index)
      db
        .collection(dbCollections.INQUIRY)
        .doc(this.selectedUser.item.id)
        .delete()
        .then(()=>{
          // console.log("Inquiry closed successfully.")
          this.selectedUser=''
        })
        .catch(error=>{
          console.log(error)
        })
    }
  },
  computed:{
    representative1(){
      if(this.selectedUser == '') {
        return this.representativeOptions1
      } else {
        return this.representativeOptions1.filter((item)=>{
          return item.value == null || (item.value !== null && item.value.id != this.selectedUser.item.id)
        })
      }
    }
  }
}
</script>
<style>
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */


.s_modal_title{
    width: 100%;
    
    font-weight: 600;
    font-size: 20px;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
