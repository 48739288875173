import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import axios from 'axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//firebase
import firebase from "@/utils/firebaseInit"
import firebaseTimeStamp from "firebase"
import {dbCollections} from "@/utils/firebaseCollection"
const db = firebase.firestore()
const months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  //get data  
  let userRoleData = [];
  let allUsers = [];
  let employeeData = [];

  db
  .collection(dbCollections.USERROLES)
  .orderBy('createdAt')
  .get()
  .then(querySnapshot=>{
    userRoleData = [];
    if(!querySnapshot.empty){
      querySnapshot.forEach(doc=>{
        userRoleData.push(doc.data())
      })
    }
  })

  db
  .collection(dbCollections.USERS)
  .orderBy('createdAt')
  .get()
  .then(querySnapshot=>{
    allUsers = [];
    if(!querySnapshot.empty){
      querySnapshot.forEach(doc=>{
        if(doc.data().isDeleted == false && doc.data().role.toLowerCase() == 'student'){
        allUsers.push(doc.data())
        }
        if(doc.data().isDeleted == false && doc.data().role.toLowerCase() == 'employee'){
          employeeData.push(doc.data())
        }
      })
    }
  })

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'sr_no', label:'Sr. No.',sortable: false },
    { key: 'employee_id', sortable: true },
    { key: 'employee_name', sortable: true },
    { key: 'employee_role',label: 'Employee\'s Role',sortable: true },
    { key: 'company_email', sortable: true },
    { key: 'action', sortable: false },
    // { key: 'email', sortable: false },
    // { key: 'role', sortable: false },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    // { key: 'status', sortable: true },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(null)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const isSpinner = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  
  watch([currentPage, perPage, searchQuery, roleFilter, planFilter], () => {
    refetchData()
  })
  
  var usersList = []
  var allUserArray = []
  var lastIntakeNo=0
  let representativeArray = [];
  let employeeArry = [];
  isSpinner.value=true
  const fetchUsers = (ctx, callback) => {
    var users = []
    // console.log("get List")
    if(usersList.length==0 && isSpinner.value)
    {
      db
        .collection(dbCollections.USERS)
        .orderBy('createdAt')
        .get()
        .then(querySnapshot=>{
          if(!querySnapshot.empty)
          {
            var startIndex = (currentPage.value-1)*perPage.value+1
            var endIndex = startIndex+perPage.value-1
            var index = 1
            var dataIndex = 1
            // console.log(startIndex,endIndex,index)
            let findIndex = querySnapshot.docs.findIndex(doc=>doc.data().id==firebase.auth().currentUser.uid)
            let currentUser = querySnapshot.docs[findIndex].data()
            querySnapshot.forEach(doc=>{
              if(currentUser.role.toLowerCase()=='admin') {
                employeeArry.push({id:doc.data().id})
              }
              if(!doc.data().isDeleted && doc.data().role == 'employee') {
                if(doc.data().representative){
                  employeeArry.push({id:doc.data().id,rId:doc.data().representative.id})
                } else {
                  employeeArry.push({id:doc.data().id})
                }
              }
            })
            function findRelatedRIds(id) {
              const relatedRIds = [];
              relatedRIds.push(id)
              const visitedIds = new Set();

              function traverseRelations(currentId) {
                  visitedIds.add(currentId);
                  
                  for (const relation of employeeArry) {
                      if (relation.rId === currentId && !visitedIds.has(relation.id)) {
                          relatedRIds.push(relation.id);
                          traverseRelations(relation.id);
                      }
                  }
              }
              
              traverseRelations(id);
              
              return relatedRIds;
          }
          let id = firebase.auth().currentUser.uid
          representativeArray = findRelatedRIds(id)
            // employeeArry.forEach((item)=>{
            //   if(representativeArray.length === 0) {
            //     if(item.id == firebase.auth().currentUser.uid){
            //       representativeArray.push(firebase.auth().currentUser.uid)
            //     }
            //     if(item.rId){
            //       if(item.rId == firebase.auth().currentUser.uid){
            //         representativeArray.push(item.id)
            //       }
            //     }
            //   } else {
            //     if(item.rId){
            //       if(representativeArray.includes(item.rId)){
            //         representativeArray.push(item.id)
            //       }
            //     }
            //   }
            // })
            querySnapshot.forEach(async(doc)=>{
              allUserArray.push(doc.data())
              //Turn isDeleted Flag = false
              // db
              //   .collection(dbCollections.USERS)
              //   .doc(doc.id)
              //   .update({
                //     isDeleted:false
                //   })
                //   .catch(error => console.log(error))
                
              if(!doc.data().isDeleted && doc.data().role == 'employee' && ((currentUser.role == 'employee' && representativeArray.includes(doc.data().id) && firebase.auth().currentUser.uid !== doc.data().id) || currentUser.role == 'admin'))
              {
                usersList.push(doc.data())
                usersList[usersList.length-1].employee_id=doc.data().employeeId
                usersList[usersList.length-1].employee_name=doc.data().name
                usersList[usersList.length-1].company_email=doc.data().companyEmail
                usersList[usersList.length-1].employee_role=doc.data().employeeRole?.name||'--'
              }
              if(dataIndex>=startIndex && dataIndex<=endIndex && !doc.data().isDeleted && doc.data().role == 'employee' && ((currentUser.role == 'employee' && representativeArray.includes(doc.data().id) && firebase.auth().currentUser.uid !== doc.data().id) || currentUser.role == 'admin'))
              {
                var joinDate = `${doc.data().dateOfJoining.split('-')[2]} ${months[doc.data().dateOfJoining.split('-')[1]-1]}, ${doc.data().dateOfJoining.split('-')[0]}`
                users.push({
                  'id':doc.data().id,
                  'employee_id':doc.data().employeeId,
                  'employee_name':doc.data().name,
                  'company_email':doc.data().companyEmail,
                  'employee_role':doc.data().employeeRole,
                  'role':doc.data().role
                })
                dataIndex++;
              }
              if(index==querySnapshot.size)
              {
                totalUsers.value = usersList.length
                isSpinner.value=false
                let count = 0
                const getStudentData = (docData) => {
                  if(users.length<=count) {
                    callback(users)
                    return;
                  } else {
                    var noOfStudentAssignee=0
                    querySnapshot.forEach(doc=>{
                        if(!doc.data().isDeleted && doc.data().role.toLowerCase() == 'student' && doc.data().representative.id == docData.id)
                        {
                          noOfStudentAssignee++;
                          count++;
                          getStudentData(users[count]);
                        } 
                        else {
                          count++;
                          getStudentData(users[count]);
                        }
                    })
                    docData.noOfStudentAssignee=noOfStudentAssignee
                    count++;
                    getStudentData(users[count]);
                  }
                }
                getStudentData(users[count])
              }
              index++;
            })
          }
          else
          {
            totalUsers.value = querySnapshot.size
            isSpinner.value=false
            callback(users)
          }
        })
        .catch(error => {
          isSpinner.value=false
          console.log(error)
        })
    }
    else
    {
      // console.log("Already has list data")
      var startIndex = (currentPage.value-1)*perPage.value+1
      var endIndex = startIndex+perPage.value-1
      var index = 1
      var totalRecords = 0
      // var dataIndex = 1
      // console.log(startIndex,endIndex,searchQuery.value)
      if(usersList.length==0)
      {
        totalUsers.value = usersList.length
        isSpinner.value=false
        let count = 0
        const getStudentData = (docData) => {
          if(users.length<=count) {
            callback(users)
            return;
          } else {
            var noOfStudentAssignee=0
            allUserArray.forEach(doc=>{
                if(!doc.isDeleted && doc.role.toLowerCase() == 'student' && doc.representative.id == docData.id)
                {
                  noOfStudentAssignee++;
                  count++;
                  getStudentData(users[count]);
                } 
                else {
                  count++;
                  getStudentData(users[count]);
                }
            })
            docData.noOfStudentAssignee=noOfStudentAssignee
            count++;
            getStudentData(users[count]);
          }
        }
        getStudentData(users[count])
        // callback(users)
        return;
      }

      if(isSortDirDesc.value==false)
      {
        // console.log("Asc")
        usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? 1 : -1))//Asc
      }
      else if(isSortDirDesc.value==true)
      {
        // console.log("Desc")
        usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? -1 : 1))//Desc
      }

      usersList.forEach(data=>{
        var joinDate = `${data.dateOfJoining.split('-')[2]} ${months[data.dateOfJoining.split('-')[1]-1]}, ${data.dateOfJoining.split('-')[0]}`
        if(searchQuery.value=='')
        {
          if(index>=startIndex && index<=endIndex && !data.isDeleted)
          {
            users.push({
              'id':data.id,
              'employee_id':data.employeeId,
              'employee_name':data.name,
              'company_email':data.companyEmail,
              'employee_role':data.employeeRole,
              'role':data.role,
            })
            // dataIndex++;
          }
          if(index==usersList.length)
          {
            // if(currentPage.value > 1 && users.length==0)
            // {
            //   currentPage.value -= currentPage.value
            // }
            totalUsers.value = usersList.length
            isSpinner.value=false
            let count = 0
            const getStudentData = (docData) => {
              if(users.length<=count) {
                callback(users)
                return;
              } else {
                var noOfStudentAssignee=0
                allUserArray.forEach(doc=>{
                    if(!doc.isDeleted && doc.role.toLowerCase() == 'student' && doc.representative.id == docData.id)
                    {
                      noOfStudentAssignee++;
                      count++;
                      getStudentData(users[count]);
                    } 
                    else {
                      count++;
                      getStudentData(users[count]);
                    }
                })
                docData.noOfStudentAssignee=noOfStudentAssignee
                count++;
                getStudentData(users[count]);
              }
            }
            getStudentData(users[count])
            // callback(users)
          }
          index++;
        }
        else if(searchQuery.value!='' && !data.isDeleted)
        {
          if(
            data.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            data.companyEmail.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            data.employeeId.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            data.employeeRole && data.employeeRole.name.toLowerCase().includes(searchQuery.value.toLowerCase())
          )
          {
            users.push({
              'id':data.id,

              'employee_id':data.employeeId,
              'employee_name':data.name,
              'company_email':data.companyEmail,
              'employee_role':data.employeeRole,
              'role':data.role,
            })
            totalRecords++
          }
          if(index==usersList.length)
          {
            // if(currentPage.value > 1 && users.length==0)
            // {
            //   currentPage.value -= currentPage.value
            // }

            var tmp = []
            var dataIndex = 1

            users.forEach(FD=>{
              if(dataIndex>=startIndex && dataIndex<=endIndex && !FD.isDeleted)
              {
                tmp.push(FD)
              }
              dataIndex++
            })
            let count = 0
            const getStudentData = (docData) => {
              if(tmp.length<=count) {
                callback(tmp)
                return;
              } else {
                var noOfStudentAssignee=0
                allUserArray.forEach(doc=>{
                    if(!doc.isDeleted && doc.role.toLowerCase() == 'student' && doc.representative.id == docData.id)
                    {
                      noOfStudentAssignee++;
                      count++;
                      getStudentData(tmp[count]);
                    } 
                    else {
                      count++;
                      getStudentData(tmp[count]);
                    }
                })
                docData.noOfStudentAssignee=noOfStudentAssignee
                count++;
                getStudentData(tmp[count]);
              }
            }
            getStudentData(tmp[count])

            totalUsers.value = users.length
            isSpinner.value=false
            callback(tmp)
          }
          index++;
        }
        else
        {
          isSpinner.value=false
        }
      })
    }

    // for (let index = 0; index < 10; index++) {
    //   users.push({
    //     'No':index,
    //     'Date of Inquiry':`Date ${index}`,
    //     'Student Name':`Name ${index}`,
    //     'Phone no.':9462489464654,
    //     'College Name.':`Test`,
    //     'Branch.':`abcd`,
    //     'CGPA(%).':(index+1*(Math.random()*100)%100).toFixed(2),
    //     'actions':`<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create Account </b-button> <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create New Inquiry </b-button>`
    //   })
    // }
    // callback(users)
  }

  // const closeInquiry = (ctx, callback)=>{
  //   var index = (currentPage.value-1) * perPage.value + ctx.index + 1
  //     console.log("closeInquiry",index,ctx)
  //     db
  //       .collection(dbCollections.USERS)
  //       .doc(ctx.item.id)
  //       .update({
  //         isDeleted:true
  //       })
  //       .then(()=>{
  //         usersList.splice(index-1,1)
  //         console.log(usersList)
  //         refetchData()
  //       })
  //       .catch(error=>{
  //         console.log(error)
  //       })
  // }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  function searchRepresentative(row,representative,id,cb){
    var currentUsers = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(row,"row")
    if(row.representative !== ''){
      if(row.representative.id == id){
          let updateObj = {
            'representative':representative,
            'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
          }
          db.collection(dbCollections.USERS).doc(row.id).update(updateObj)
          let notificationObj = {
              title: `Student Edited`,
              type: 'studentEdited',
              userId: row.id,
              currentUser:firebase.auth().currentUser.uid
          }
          if(representative) {
              notificationObj.message = `Your representative has been changed to ${representative.name} by ${currentUsers.fullName}`;
          } else {
              notificationObj.message = `Your representative has been changed to ${currentUsers.fullName}`;
          }
          axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then(()=>{
          }).catch((error)=>{
              console.error(error);
          })
          let indexOfStudent = allUserArray.findIndex(x => x.id === row.id);
          if(indexOfStudent !== -1){
            allUserArray[indexOfStudent].representative = representative
          }
          let indexOfStudent2 = allUsers.findIndex(x => x.id === row.id);
          if(indexOfStudent2 !== -1){
            allUsers[indexOfStudent2].representative = representative
          }
          cb(true)
      }else{
        cb(true)
      }
    }else{
      cb(true)
    }
  }
  
  const deleteEmployee = (selectedEmp,representative,employeeReprese,cb)=>{
    var currentUsers = JSON.parse(localStorage.getItem('currentUser'));
    var index = (currentPage.value-1) * perPage.value + selectedEmp.index + 1
    // console.log(userRoleData,"closeInquiry",index,selectedEmp.item)
    let fileArry = allUsers 
    let count = 0;
    if(fileArry.length > 0){//if student - --> usertype is exist 
      const uploadFileFunction = (row) => {
          if(count >= fileArry.length){  
              // console.log('Remove from student representative')
              let count2 = 0;
              const updateFunctionData = (row) => {
                if(count2 >= employeeData.length){
                  //DELETE FROM FIREBASE COLLECTION START
                  if(selectedEmp.item.employee_role !== null){//if emp role assign
                    let roleId = selectedEmp.item.employee_role.id;
                    let assignRoleUser = [];                  
                    if(roleId !== undefined && roleId !== ''){
                        userRoleData.forEach(values=>{//then update role collection assign array
                          if(values.id == roleId){
                            assignRoleUser = values.assignUser
                          }
                        })
                        if(assignRoleUser.length > 0){
                          let searchIndex = assignRoleUser.findIndex(elem=>{
                            return elem == selectedEmp.item.id
                          })
                          if(searchIndex > -1){
                            assignRoleUser.splice(searchIndex,1)
                            let updateObj = {
                              'assignUser':assignRoleUser
                            }
                            db.collection(dbCollections.USERROLES).doc(roleId).update(updateObj)
                            db.collection(dbCollections.USERS).doc(selectedEmp.item.id).update({
                              updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                              isDeleted:true
                            })
                            .then(()=>{
                              usersList.splice(index-1,1)
                              refetchData()
                              cb(true,'success')
                            })
                            .catch(error=>{
                              console.log(error)
                              cb(false,error.message)
                            })
                          }else{
                            db
                            .collection(dbCollections.USERS)
                            .doc(selectedEmp.item.id)
                            .update({
                              updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                              isDeleted:true
                            })
                            .then(()=>{
                              usersList.splice(index-1,1)
                              // console.log(usersList)
                              refetchData()
                              cb(true,'success')
                            })
                            .catch(error=>{
                              console.log(error)
                              cb(false,error.message)
                            })
                          }
                        }
                    }
                  }
                  else{ //JUST UPDATE FROM USER COLLECTION
                      db.collection(dbCollections.USERS).doc(selectedEmp.item.id).update({
                          updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                          isDeleted:true
                      }).then(()=>{
                          usersList.splice(index-1,1)
                          // console.log(usersList)
                          refetchData()
                          cb(true,'success')
                      }).catch(error=>{
                          console.log(error)
                          cb(false,error.message)
                      })
                  } 
                  //DELETE FROM FIREBASE COLLECTION END
                  return
                } else {
                  if(row.representative){
                    if(row.representative.id == selectedEmp.item.id){
                      let notificationObj = {
                          title: `Employee Edited`,
                          type: 'employeeEdited',
                          userId: row.id,
                          currentUser:firebase.auth().currentUser.uid
                      }
                      if(employeeReprese) {
                          notificationObj.message = `Your representative has been changed to ${employeeReprese.name} by ${currentUsers.fullName}`;
                      } else {
                          notificationObj.message = `Your representative has been changed to ${currentUsers.fullName}`;
                      }
                      axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then(()=>{
                      }).catch((error)=>{
                          console.error(error);
                      })
                      db.collection(dbCollections.USERS).doc(row.id).update({'representative':employeeReprese,'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()}).then(()=>{
                        let findIndex = employeeData.findIndex(x => x.id === row.id);
                        if(findIndex !== -1){
                          employeeData[findIndex].representative = employeeReprese
                        }
                        count2++
                        updateFunctionData(employeeData[count2])
                      })
                      .catch(error=>{ 
                        console.log(error) 
                        count2++
                        updateFunctionData(employeeData[count2])
                      })
                    } else {
                      count2++
                      updateFunctionData(employeeData[count2])
                    }
                  } else {
                    count2++
                    updateFunctionData(employeeData[count2])
                  }
                }
              }
              updateFunctionData(employeeData[count2])
            return;                
          }else{
            searchRepresentative(row,representative,selectedEmp.item.id,(saveRes)=>{
                count += 1;
                uploadFileFunction(fileArry[count]);
            });
          }              
      }    
      uploadFileFunction(fileArry[count]);   
    }else{ //ELSE
        //DELETE FROM FIREBASE COLLECTION START
        if(selectedEmp.item.employee_role !== null){ //if emp role assign
            let roleId = selectedEmp.item.employee_role.id;            
            let assignRoleUser = [];
            if(roleId !== undefined && roleId !== ''){
                // get role assign array
                userRoleData.forEach(values=>{
                  if(values.id == roleId){
                    assignRoleUser = values.assignUser
                  }
                })

              if(assignRoleUser.length > 0){ //then update role collection assign array
                let searchIndex = assignRoleUser.findIndex(elem=>{
                  return elem == selectedEmp.item.id
                })
                if(searchIndex > -1){
                  assignRoleUser.splice(searchIndex,1)
                  let updateObj = {
                    'assignUser':assignRoleUser
                  }
                  db.collection(dbCollections.USERROLES).doc(roleId).update(updateObj) //SPLICE ID FROM USERROLE ASSIGN USER
                  db.collection(dbCollections.USERS).doc(selectedEmp.item.id).update({
                    updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                      isDeleted:true
                  }).then(()=>{
                      usersList.splice(index-1,1)
                      // console.log(usersList)
                      refetchData()
                      cb(true,'success')
                  }).catch(error=>{
                    console.log(error)
                    cb(false,error.message)
                  })
                }else{
                  db.collection(dbCollections.USERS).doc(selectedEmp.item.id).update({ //JUST UPDATE FROM USER COLLECTION
                    updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    isDeleted:true
                  }).then(()=>{
                    usersList.splice(index-1,1)
                    // console.log(usersList)
                    refetchData()
                    cb(true,'success')
                  }).catch(error=>{
                    console.log(error)
                    cb(false,error.message)
                  })
                }
              }
            }
        }
        else
        {
            db.collection(dbCollections.USERS).doc(selectedEmp.item.id).update({
                updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                isDeleted:true
            }).then(()=>{
                usersList.splice(index-1,1)
                // console.log(usersList)
                refetchData()
                cb(true,'success')
            }).catch(error=>{
                console.log(error)
                cb(false,error.message)
            })
        }
        //DELETE FROM FIREBASE COLLECTION END
    }
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    // closeInquiry,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    deleteEmployee,

    isSpinner,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
